.print {
    .section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: auto;
    }

    table {
        width: 600px;
        margin-bottom: 25px;

        td {
            padding-top: 8px;
        }
    }

    .indented {
        padding-left: 25px;
    }
}