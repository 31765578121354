@mixin is-larger-than($breakpoint) {
  @media all and (min-width: $breakpoint) {
    @content;
  }
}

@mixin is-smaller-than($breakpoint) {
  @media all and (max-width: ($breakpoint - 1px)) {
    @content;
  }
}

@mixin is-between($start, $end) {
  @media all and (min-width: $start) and (max-width: $end) {
    @content;
  }
}