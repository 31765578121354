h1,
h2,
h3,
h4,
h5 {
  font-family: "Cabin", sans-serif;
  font-weight: 700;
  color: $primary;
}

h1 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 60px;
  line-height: 110%;
  font-weight: 500;
}

h2 {
  font-size: 36px;
}

h3,
h5 {
  font-size: 20px;
  font-family: "Space Mono", sans-serif;
}

body {
  font-family: "Cabin", sans-serif;
  font-size: 18px;
}

.ingress {
  font-size: 24px;
  font-weight: normal;
  letter-spacing: normal;
}
