main.dashboard {
  section.hasBottomMargin {
    margin-bottom: var(--space-l);
  }

  button.safeButton {
    background: $primary;
    color: white;
    margin-right: 16px;
  }

  .MuiTextField-root {
    margin: 0 0 var(--space-m) 0;
  }

  .MuiFormHelperText-contained {
    margin-left: 0;
    color: var(--onSurface);
  }

  .formControls {
    margin-bottom: var(--space-m);
  }
}

.section-hero {
  background: $background-header;
  padding-top: var(--space-xl);

  h1 {
    text-align: center;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 6px;
  }

  h3 {
    text-align: center;
    margin-bottom: -24px;
  }

  padding-bottom: var(--space-l);

  &.is-loading {
    div.dashboard-loading-data {
      max-width: 600px;
      margin: auto;
      color: white;
      text-align: center;
    }

    h5 {
      text-align: center;
      color: white;
    }
  }
}

#user-settings {
  .MuiFormGroup-root {
    margin-bottom: 1rem;
  }
}

p {
  font-size: 16px;
  line-height: 1.6;
}

label {
  font-size: 16px;
  font-weight: 500;
}

.tag {
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 12px;
  background-color: goldenrod;
  display: inline-flex;
  padding: 4px 16px;
  color: $white;
  text-transform: uppercase;
  border-radius: 4px;
  font-family: "Space Mono", sans-serif;

  &.active {
    background-color: darkgreen;
  }
}

.teasers {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 12px;
  margin-bottom: 24px;
}

.teaser-testament {
  width: 320px;
  height: 160px;
  margin: 12px;
  border: 1px solid white;

  .media {
    height: 64px;
    width: 64px;
    display: inline-flex;
    margin-top: 12px;
  }
  h2 + span {
    font-family: "Space Mono", sans-serif;
    color: #1a1a1a;
    font-size: 12px;
    line-height: 130%;
  }

  > * {
    height: 100%;
  }

  .icon-container {
    height: 64px;
    width: 128px;
    border-radius: 50%;
    background: red;
  }

  &.teaser-testament-new {
    background: inherit;
    border: 1px dashed $primary;
    text-align: center;
    box-shadow: none;

    .link {
      color: $primary;
      font-weight: 700;
      font-size: 16px;
      font-family: "Space Mono", sans-serif;
    }
  }
}

.local-nav {
  margin-top: var(--space-l);

  nav a {
    text-decoration: none;
  }
}

/* dette er global styling  så burde flyttes */
button {
  &.primaryButton {
    color: white;
    font-family: "Space Mono", sans-serif;
    font-size: 18px;
    text-transform: none;
    height: 48px;
    padding-left: 16px;
    padding-right: 16px;
  }

  &.secondaryButton {
    color: $primary;
    border: 1px solid $primary;
    font-family: "Space Mono", sans-serif;
    font-size: 16px;
    text-transform: none;
    height: 48px;
    padding-left: 16px;
    padding-right: 16px;

    + button.secondaryButton {
      margin-left: 24px;
    }
  }
}

.payment-card {
  width: 312px;
  height: 156px;
  display: flex;
  align-items: center;

  .media {
    height: 64px;
    width: 64px;
    display: inline-flex;
    margin-left: 24px;
  }

  .content {
    display: inline-flex;
    flex-flow: column;

    span {
      display: flex;
    }
  }
}

.form a {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.alternative-container {
  display: flex;
  align-items: center;
  font-family: "Cabin", sans-serif;
}
