.person-list {

  .person-list-container{
	margin-bottom: var(--space-l);
  }

  .person-list-add {
    margin-top: 0;
    margin-bottom: var(--space-l);
  }
}
