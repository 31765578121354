.static-list {
    display: flex;
}

.static-list.vertical {
    flex-direction: row;
}

.static-list.vertical {
    flex-direction: column;
}
