.stats {
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  table {
    width: 500px;
    margin-bottom: 25px;

    td {
      padding-top: 8px;
    }

    td:nth-child(2) {
      text-align: right;
    }
  }

  .indented {
    padding-left: 25px;
  }
}

.org-stats {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 60% 10% 30%;
  grid-template-rows: auto;
  grid-template-areas: 
    "header header header"
    "full full full"
    "graph . sidebar";
  padding-top: var(--space-l);
  justify-self: center;

  @include is-smaller-than($mobileLarge) {
    max-width: 900px;
    width: 100%;
    grid-template-columns: 100%;
    grid-template-areas: 
    "header"
    "full"
    "graph" 
    "sidebar";
  }

  @include is-smaller-than($mobile) {
    max-width: 700px;
    width: 100%;
    grid-template-columns: 100%;
    grid-template-areas: 
    "header"
    "full"
    "graph" 
    "sidebar";
  }
  

  h2 {
    grid-area: header;
    padding: var(--space-l);
    padding-bottom: 0;
    margin-bottom: 0;
    height: 100%;
    
    @include is-smaller-than($mobile) {
      padding: var(--space-m);
    }
  }

  .key-value-section {
    grid-area: full;
    min-height: 110px;
    padding: var(--space-l);

    @include is-smaller-than($mobile) {
      padding: var(--space-m);
    }

    .box {
      display: flex;
      float: left;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      background-color: rgba(81, 30, 126, 0.1);
      height: 120px;
      width: 180px;
      margin: var(--space-s);

      @include is-smaller-than($mobile) {
        width: 100%;
        margin-left: 0;
      }

      span {
        margin: var(--space-xs);
        text-align: center;
      }

      .number {
        font-size: 20px;
        font-weight: 700;
        color: $primary;
      }

      .text{
        height: 40px;
      }
    }
  }

  .graph-section {
    grid-area: graph;
    width: 100%;
    padding-left: var(--space-l);

    @include is-smaller-than($mobile) {
      padding-left: var(--space-m);
    }

    .graph {
      display: flex;
      flex-direction: column;
      width: 90%;
      margin-bottom: var(--space-xl);
      max-height: 600px;

      h3 {
        padding-bottom: var(--space-m);
      }
    }

    .year-dropdown {
      margin-right: var(--space-m);
      margin-bottom: -40px;
      width: 100px;
      align-self: flex-end;
    }
  }

  .list-section {
    grid-area: sidebar;
    padding-left: var(--space-l);
    padding-bottom: var(--space-xl);

    @include is-smaller-than($mobile) {
      padding-left: var(--space-m);
      padding-right: var(--space-m);
    }

    ul {
      list-style-type: none;
      padding: 0;
      li {
        display: flex;
        flex-direction: column;
        padding: var(--space-xs);
      }
    }
  }

  .statistics-loading-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  button.show-more {
    width: 63px;
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: "Cabin", sans-serif;
    /*input has OS specific font-family*/
    color: var(--primary);
    &:hover {
      text-decoration: underline;
    }
    cursor: pointer;
  }
}
