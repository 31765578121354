.card-element-container{
    margin: 0px 0px 16px 0px;
    padding: 18px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
}

.questionLabel {
    font-family: 'Cabin', sans-serif;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 4px;
    margin-top: 16px;
    color: var(--onSurface);
  }