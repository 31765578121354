.testament-page .name {
  font-weight: 700;
}

.screening-image {
  display: block;
  width: 410px;
  height: 250px;
  margin: var(--space-l) auto;

  @include is-smaller-than($mobile) {
    width: 100%;
    height: auto;
  }
}

.screening-form {
  @include is-smaller-than($mobile) {
    .static-list.primary-action {
      flex-direction: column;

      button {
        width: 100%;
      }
    }
  }
}

.screening-page {
  @include is-smaller-than($mobile) {
    button {
      width: 100%;
    }
  }
}
