@import "../../css/_variables.scss";
@import "../../css/mixins/rwd.scss";

header {
  position: fixed;
  top: 0;
  width: 100%;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.38); */
  background-color: var(--background-header);
  color: $primary;
  z-index: 100;

  button .MuiButton-label {
    text-transform: none;
  }

  .frontHeaderSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--space-xs) 0;
    margin: 0 var(--space-m);
    font-family: 'Cabin', sans-serif;
    font-weight: 700;
    min-height: var(--space-xl);

    .dashboard-link {
      font-family: "Space Mono", sans-serif;

      span.material-icons {
        font-size: 20px !important;
        font-weight: 600;
      }
    }

    .right {
      font-size: 16px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $primary;
    }
  }

  .verticalCenter {
    display: flex;
    align-items: center;

    span.textLarge {
      font-size: 28px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }

  .minside-txt {
    padding-left: 5px;
  }

  .logo-right {
    img.logo {
      margin-right: 10px;
    }
  }

  img.logo {
    height: 38px;
    margin-right: 6px;
  }
}


@include is-smaller-than($mobileLarge) {
  header {
    .verticalCenter {
      span.textLarge {
        font-size: 20px;
      }
    }
  }
}

