$mobileSmall: 320px;
$mobile: 736px;
$mobileLarge: 980px;
$tablet: 1280px;
$desktop: 1690px;

$primary: #39134c;
$secondary: #AECDA8;
$background: #eeeeee;
$background-header: #ffe1b7;
$white: #FFFFFF;
$lightGray: #EFEBEE;
$brightYellow: #f8e71c;
$black: #1A1A1A;

$boxshadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
