@import "../../../css/variables";
@import "../../../css/mixins/rwd";

.testament-page {

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  span.input-answered {
    background-color: $lightGray;
  }

  span.input-placeholder {
    background-color: $brightYellow;
  }

  .preview-logo {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;

    img {
      width: 40px;
      margin-right: 0;
    }

    .will-logo-text {
      font-family: "Cabin", sans-serif;
      font-size: 12px;
      color: var(--primary);
      font-weight: 700;
      text-align: center;
    }
  }

  .will {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 3px;
    box-shadow: $boxshadow;
    background-color: white;
    padding: var(--space-l);
    /* height: calc(100vh - 165px); */
    margin-bottom: var(--space-l);
    max-width: 800px;

    /* typography */
    p,
    li,
    td,
    th,
    dt,
    dd {
      // font-family: 'Montserrat', sans-serif;
      font-family: "Cabin", sans-serif;
      color: var(--onSurface);
      font-size: 12px;
      font-weight: normal;
    }

    h2,
    h3,
    h4,
    h5 {
      color: var(--primary);
      font-family: "Cabin", sans-serif;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 18px;
      margin: 0 0 8px;
    }

    h4 {
      font-size: 16px;
      margin: 0 0 4px;
    }

    h5 {
      margin: 0;
      font-size: 14px;
    }

    @include is-smaller-than($tablet) {
      overflow-y: hidden;
      height: auto;
    }

    ol,
    ul {
      margin-bottom: var(--space-s);
    }
  }

  .will-draft {
    background-image: url("../../../media/stamp.png");
    background-position: center;
    background-repeat: no-repeat;
  }

  .life-will-draft {
    background-image: url("../../../media/life-will-stamp.png");
    background-position: center;
    background-repeat: no-repeat;
  }

  /* Table */
  table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: var(--space-l);
  }

  table tbody {
    border-top: 1px solid #222222;
    border-bottom: 1px solid #222222;
  }

  th,
  td {
    text-align: left;
    vertical-align: top;
    padding: 8px 24px 8px 0px;
  }

  table span {
    display: flex;
  }

  table span a {
    display: contents;
    text-decoration: none;
  }

  .tablenote {
    margin-top: -36px;
    margin-bottom: var(--space-l);
  }

  /* Content */
  .name {
    font-weight: 500;
    display: inline-flex;
    color: var(--primary);
  }

  .birthdate {
    display: inline-flex;
  }

  .address {
    white-space: nowrap;
  }

  .signatures {
    display: flex;
    flex-flow: row wrap;
  }

  .signaturePlace {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .signature {
    width: 50%;
    flex-basis: auto;
    margin-bottom: 3em;
    padding-right: var(--space-l);
    box-sizing: border-box;

    dl {
      line-height: 1.6;
    }

    .is-hidden {
      display: none;
    }

    dl dt {
      font-weight: normal;
      margin-top: 1em;
      margin-bottom: 0.3em;
      margin-left: 0;
    }

    dl dd {
      margin-bottom: 0.5em;
      margin-left: 0;
    }

    dl dd span {
      white-space: nowrap;
    }

    dd.empty::after {
      content: "";
      display: block;
      border-bottom: 1px dotted var(--primary);
      margin-top: 2.8em;
      width: 100%;
    }
  }

  /* footer */
  .will-footer {
    color: var(--primary);
    font-family: "Cabin", sans-serif;
    font-size: 10px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    a {
      text-decoration: none;
    }
  }

  .will-footer img {
    width: 1.4em;
    margin-right: 12px;
    margin-bottom: -3px;
  }

  //.pagenumber {
  //  right: var(--space-l);
  //}
  //
  //.copyright {
  //  left: var(--space-l);
  //}
}