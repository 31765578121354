/* Set the border-box box model globally */
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

.container {
  display: table;
  align-content: center;
  flex-direction: row;
  height: 100%;

  @include is-smaller-than($mobile) {
    flex-direction: column;
  }
}

.section {
  width: 50%;
  display: table-cell;
  height: 100%;
  padding: var(--space-l);
  background-color: rgba(0, 0, 0, 0);

  &.testament-page {
    height: 100%;
    overflow-y: scroll;
    position: absolute;
    top: var(--space-xl);
    right: 0;
  }

  @include is-smaller-than($tablet) {
    width: 100%;
    height: auto;
    padding: 80px 25px 48px 25px;

    &.testament-page {
      height: 100%;
      position: initial;
      padding: 0;
    }
  }
}



.row {
  width: 100%;
  display: flex;
  flex-direction: column;

  .col {

  }
}

body {
  @include is-smaller-than($mobileLarge) {
    .hidden-xs {
      display: none;
    }
  }
  @include is-smaller-than($tablet) {
    .hidden-sm {
      display: none;
    }
  }

  @include is-larger-than($tablet) {
    .hidden-md {
      display: none;
    }
  }

  @include is-larger-than($desktop) {
    .hidden-lg {
      display: none;
    }
  }
}
