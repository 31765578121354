input[type="text"] {
  background: white;
}

input[type="text"]:read-only,
textarea:read-only {
  background-color: transparent;
}

.app .MuiOutlinedInput-input {
  padding: 12.5px 14px;
}

.app .MuiInputLabel-outlined {
  transform: translate(14px, 14px) scale(1);
}

.app .MuiOutlinedInput-multiline {
  padding: 0;
  background: white;
}

button.primaryButton {
  font-family: "Space Mono", sans-serif;
  font-size: 18px;
  text-transform: none;
  height: 48px;

  &:not([disabled]) {
    border: 1px solid var(--primary);
  }
}

.formControlRadioLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
}

button.primaryButton + button.primaryButton {
  margin-left: var(--space-m);
}

.static-list {
  margin-bottom: 64px;
}

.MuiRadio-root {
  .MuiIconButton-label {
    background: white;
    border-radius: 50%;
  }
}

.MuiCheckbox-root {
  .MuiIconButton-label {
    background: white;
  }
}

.MuiFormHelperText-root {
  color: var(--onSurface) !important;
}

.MuiFormHelperText-contained {
  margin-left: 0 !important;
}

.MuiGrid-item {
  padding-bottom: var(--space-m);
}

.MuiGrid-spacing-xs-4 > .MuiGrid-item {
  padding: 12px 16px !important;
}

@include is-smaller-than($mobile) {
  .form .form-group .row.content-row .col.form-elements {
    padding-right: var(--space-m);
    padding-left: var(--space-m);
  }
}
