.dynamic-list {
  display: flex;
  flex-direction: column;
  width: 100%;

  .list-items .list-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .list-item {
      margin-right: var(--space-s);
      align-self: center;

    }

    .list-remove {
      display: flex;
      align-self: center;
      align-content: center;
    }
  }
  .list-action {
    margin-top: var(--space-m);
    margin-bottom: var(--space-xl);
  }
}
