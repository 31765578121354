.form-group {
  $groupColor: rgba(0, 0, 0, 0.6);

  .page-number {
    background-color: var(--primary);
    width: 28px;
    height: 28px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Space Mono', sans-serif;
    font-weight: 700;
    font-size: 14px;
    
    > span {
      color: white;
    }
  }

  .row {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    flex: 1 0 auto;

    &.content-row {
      margin-top: var(--space-m);
      margin-bottom: var(--space-m);
      display: table;

      .col {
        display: table-cell;

        &.form-group-line {
          .vertical-line {
            height: 100%;
            width: 14px;
            border-right: 1px solid var(--onDisabled);
          }
        }

        &.form-elements {
          vertical-align: top;
          padding-right: 0;
          padding-left: var(--space-l);
          padding-bottom: var(--space-xl);
        }
/*        &.form-elements > div{
          margin-bottom: var(--space-l);
        } */
      }
    }

    .col {
      &.col-page {
        flex-grow: 0;
        flex-shrink: 0;
        width: var(--space-m);
        height: 100%;
      }

      &.form-title {
        flex-grow: 1;
        padding: 0 var(--space-m);
        font-size: 20px;
        height: 24px;

        h2 {
          margin: 0;
          padding: 0;
          line-height: 24px;
          font-family: 'Space Mono', sans-serif;
          font-weight:  700;
          font-size: 20px;
        }
      }

      &.form-status {
        text-align: right;
        color: var(--primary);
        font-size: 14px;
        font-family: 'Space Mono', sans-serif;
        font-weight:  700;
      }
    }
  }
}