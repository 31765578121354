@import "css/main.scss";

.app {
  //height: 100%;
}

body {
  //overflow: hidden;
}

main {
  height: calc(100% - 64px);
  margin-top: var(--space-xl);
}

h2 {
  font-weight: bold;
  font-size: 36px;
  color: $primary;
  min-height: 36px;
  line-height: 1.33;
  letter-spacing: normal;
  margin: 0 0 24px;
}

h3 {
  margin: 0 0 4px;
}

h4 {
  margin: 0 0 0px;
  font-size: 16px;
}

p {
  margin: 0 0 24px;
}

hr {
  border: 1px solid var(--onDisabled);
  border-bottom: none;
  margin-bottom: var(--space-m);
}

.primaryColor {
  color: var(--primary);
}

// denne skal ikke være her. Bør være under header men der fikk jeg ikke includen til å funke.
img.logo {
  height: 48px;

  @include is-smaller-than($mobile) {
    height: 36px;
  }
}

.frontPage {
  // color: var(--surface);
  background-color: var(--background);
}

.no-link {
  color: inherit;
  text-decoration: none;
}

.form {
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.5px;
  padding-right: var(--space-m);
  padding-left: var(--space-xxl);

  @include is-smaller-than($mobile) {
    padding-left: var(--space-m);
  }
}

.testament-form {
  position: absolute;
  overflow: auto;
  top: var(--space-xl);
  padding-bottom: var(--space-xxl);
}

.formControl {
  margin-bottom: var(--space-m);
}

.textFormControl {
  font-family: "Cabin", sans-serif;
  color: var(--onSurface);
  padding-bottom: var(--space-m);

  h3 {
    padding-top: var(--space-m);
  }

  p {
    color: inherit;
  }

  p:last-child {
    margin: 0;
  }
}

ul,
ol {
  margin: 0;
}

li {
  margin-bottom: 8px;
}

.questionLabel {
  font-family: "Cabin", sans-serif;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 0px;
  color: var(--onSurface);
}

.dateSelector {
  flex-direction: row;
  width: 100%;
}

button.primaryButton {
  background-color: var(--primary);
  letter-spacing: 1.25px;
  padding: 0 var(--space-m);
  // margin-right: var(--space-s);
  font-family: "Space Mono", sans-serif;
  font-weight: 700;
}

button.secondaryButton {
  color: var(--primary);
  letter-spacing: 1.25px;
  padding: 0 var(--space-m);
  font-family: "Space Mono", sans-serif;
  font-weight: 700;
}

.note {
  font-size: 16px;
  color: var(--onBackground);
  padding-top: 45px;
}

.help-text-icon {
  background-color: $primary;
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;

  >span {
    font-family: "Cabin", sans-serif;
    font-size: 16px;
    color: white;
  }
}

.imageContent {
  margin-bottom: 32px;
  max-width: 100%;

  object {
    max-width: 100%;
  }
}

input[type="number"] {
  background: white;
}

input[type="tel"] {
  background: white;
}

input[type="password"] {
  background: white;
}

.negativTopMargin {
  margin-top: -24px;
}