@import "../../../css/variables";

.distribution-list {
    display: flex;
    flex-direction: column;
	width: 100%;

	.distribution-list-action {
		color: var(--primary);
		background: rgba(0, 0, 0, 0.04) !important;
		margin-bottom: var(--space-s);

		&:not(:last-child) {
			margin-right: var(--space-s);
		}
	}
	
	.distribution-list-result{
		span {
			display: block;
			margin-right: 1rem;
		}
	}

	.distribution-person-item{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-bottom: var(--space-l);
		
		.distribution-person-item-heir{
			margin-right: var(--space-m);
			margin-bottom: var(--space-s);
			width: 50%;
		}
		.distribution-person-item-fraction{
			margin-right: var(--space-m);
			width: 40%;
		}
	}
	.distribution-list-actions{
		Button{
			margin-right: var(--space-m);			
			color: $primary;
		}
	}
}
