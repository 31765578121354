footer {
  .footer-inner {
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;

    font-size: 12px;
    font-family: 'Cabin', sans-serif;
    color: #444;

    a {
      color: var(--primary);
      text-decoration: none;
    }
  }
}