main.payment {
	.section {
		display: block;
		width: auto;

		form {
			width: 100%;
		}
	}

	button.complete-payment-button {
		margin: 1rem 0;
		border: solid 1px #ccc;
		background: #fafafa;
		padding: 1rem;
	}

	.error {
		padding: 1rem;
		border: solid 1px black;
	}
}
