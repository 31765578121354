.organisation-items{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: var(--space-l);

    .organisation-item{
        margin-right: var(--space-s);
        width: 45%;
        min-width: 150px;
    }

    .item{
        margin-right: var(--space-s);
    }
}