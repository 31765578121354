/* @font-face{
  font-family: "Roboto";
  src: url('https://fonts.googleapis.com/css?family=Roboto');
} */

:root {
  --primary: #39134c;
  --adjacent: #AECDA8;
  --onSurface: rgba(0, 0, 0, 0.87);
  --onBackground: rgba(0, 0, 0, 0.60);
  --onDisabled: rgba(0, 0, 0, 0.38);
  --background: #F4F4F4;
  --background-header: #ffe1b7;
  --surface: #ffffff;
  --onError: #c92856;

  --space-xxs: 4px;
  --space-xs: 8px;
  --space-s: 12px;
  --space-m: 24px;
  --space-l: 48px;
  --space-xl: 64px;
  --space-xxl: 96px;
}

html{
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: Georgia, Times, 'Times New Roman', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: var(--background);
}

#root {
  display: flex;
  flex-direction: column;
  align-content: center;
}

#root .app {
  flex-grow: 1;
}
